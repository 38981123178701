import React, { useEffect, useState } from 'react'
import {doc , addDocs, collection, getDocs, orderBy, query, getDoc } from 'firebase/firestore'
import { firestore } from '../db'
import author from '../Images/author.png'
import { useNavigate, useParams } from 'react-router-dom'

export default function Newsthread(props) {
  
  const [firstScriptCompleted, setFirstScriptCompleted] = useState(false);

    props.setPage('home')
    

    const {id} = useParams()
    const[data, setData] = useState([])
    const[senderdata, setSenderData] = useState([])


    const fetchData  = async () => {
    const docRef = doc(firestore, "news", id);
    const docSnap = await getDoc(docRef)
    if (docSnap.exists) {
        setData(docSnap.data());
        setFirstScriptCompleted(true);
    }
    
    
    }
    useEffect(() =>
    {
        fetchData()
        if (firstScriptCompleted) {
          fetchuserData()
      }
        
    },[firstScriptCompleted]
    )

  const fetchuserData  = async () => {
      const docRef = doc(firestore, "users", `${data.senderdata}`);
      const docSnap = await getDoc(docRef)
      if (docSnap.exists) {
        setSenderData(docSnap.data());
      }
      
      
      }
    {  
      let role = `${senderdata.role}`
  return (
  
    <>
    
    <div className="block w-11/12 mt-6 mx-auto h-auto  border border-amber-200 rounded-md shadow backdrop-blur-2xl ">
    <div className="newinfo flex">
                <h1 className='font-bold date  text-amber-300 text-2xl bg-black/25 text-center w-14 h-auto rounded-md ml-4 my-3'>{data.date}</h1>
                <span className='font-bold title text-white text-2xl  rounded-md ml-7 my-3'>{data.title}</span>
            
                <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className='fill-white flex absolute left-24 top-12 lg:opacity-100 sm:opacity-0 ' 
                        x="0px" y="0px" width="22" height="22" 
                        viewBox="0 0 24 24">
                            
                        <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 6 L 11 12.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13 11.585938 L 13 6 L 11 6 z"></path>
                        </svg>
                        <h1 className='text-white font-bold title text-sm lg:opacity-100 sm:opacity-0 absolute left-32 top-12'>{data.day_time}</h1>
                    </div>
                </div>
            <div className='lg:flex sm:inline-block sm:mx-auto  justify-center'>
            <div className="block w-56  lg:opacity-100 sm:opacity-0 mt-6  h-auto  border border-amber-200 rounded-md shadow backdrop-blur-2xl ">
            <img class="w-20 p-1 mt-12 rounded-full ring-2 ring-amber-300 dark:ring-amber-500 mx-auto" src={senderdata.profile_pic} alt="avatar"/>

            <h3 class="font-bold text-auto text-gray-800 dark:text-white mt-3 text-center" >{senderdata.name}</h3>  
            <span className={` text-xs ml-20 font-medium me-2 px-2.5 py-0.5 rounded 
                    ${role === 'Founder' ?  'bg-red-900 text-red-300' : 
                    role === 'Head Manager' ? 'bg-pink-900 text-pink-300' :
                    role === 'Manager' ? 'bg-pink-900 text-pink-300' :
                    role === 'Admin' ? 'bg-purple-900 text-purple-300' :
                    role === 'J.R Admin' ? 'bg-purple-900 text-purple-300' :
                    role === 'Mod' ? 'bg-indigo-900 text-indigo-300': 
                    role === 'J.R Mod' ? 'bg-indigo-900 text-indigo-300': 
                    role === 'Discord Admin' ? 'bg-yellow-900 text-yellow-300':
                    'bg-green-900 text-green-300'}
                    
                    `}>{senderdata.role}</span>
            </div>
              <div className="block w-9/12 mt-6 h-auto  border border-amber-200 rounded-md shadow backdrop-blur-2xl ">
            
              <div className="nbody">
                <img className='size-11/12 border border-amber-200 mx-auto mt-4' src={data.image} alt="" />
                <div className='text-xl text-center text-white news newsui px-5 pt-6' dangerouslySetInnerHTML={{ __html: data.news }}></div>
                
                
                        <br/>
                </div>
              </div>
              </div>

    </>
  )
    }
}
