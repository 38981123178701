import React from 'react'
import PropTypes from 'prop-types'
import Navbar from './Navbar'

export default function Vote(props) {
    props.setPage('vote')

  return (
    <>
    <div className="block votebox pl-4 border  border-amber-300 drop-shadow-xl rounded-md shadow backdrop-blur-2xl ">
        <h1 className='text-white title text-2xl py-3 font-bold'>Vote</h1>
    </div>
<section className='lg:flex'>
    <div className="block lg:w-72 lg:w-72 md:w-2/4 h-auto lg:mt-6 md:mt-6 lg:ml-20  md:ml-11 pl-4 border border-amber-300 drop-shadow-xl rounded-md shadow backdrop-blur-2xl">
    <a href='https://topminecraftservers.org/vote/14956'> <div className='title text-lg mt-8 font-bold text-white border w-60 h-16 border-amber-300  rounded-md bg-gray-800/25 ease-in-out duration-100 hover:bg-transparent flex items-center justify-center text-center px-auto py-auto '>Vote Link 1</div></a>
    <a href='https://servers-minecraft.net/server-havenlands-network.39430'>  <div className='title text-lg mt-8 font-bold text-white border w-60 h-16 border-amber-300  rounded-md bg-gray-800/25 ease-in-out duration-100 hover:bg-transparent flex items-center justify-center text-center px-auto py-auto mb-8'>Vote Link 2</div></a>
        {/* <div className='title text-lg mt-8 font-bold text-white border w-60 h-16 border-amber-300  rounded-md bg-gray-800/25 ease-in-out duration-100 hover:bg-transparent flex items-center justify-center text-center px-auto py-auto '>Vote Link 3</div>
        <div className='title text-lg mt-8 mb-8 font-bold text-white border w-60 h-16 border-amber-300  rounded-md bg-gray-800/25 ease-in-out duration-100 hover:bg-transparent flex items-center justify-center text-center px-auto py-auto '>Vote Link 4</div> */}
    </div>
    <div className="md:opacity-0 lg:opacity-100 top-votes block w-8/12 h-100 mt-6 ml-3 pl-4 border border-amber-300 drop-shadow-xl rounded-md shadow backdrop-blur-2xl">
    <h1 className='text-white title text-2xl py-3 font-bold  text-center'>Top Voter</h1>
    <span className='text-gray-500 flex items-center justify-center text-center'>*Error in fetching data*</span>
    </div>
    </section>
    </>

  )
}
