import "./App.css";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
// import Gamemode from "./components/Gamemode";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import TeamMember from "./components/TeamMember";
import Applications from "./components/Applications";
import Contact from "./components/Contact";
import { useState } from "react";
import Gamemodes from "./components/Gamemodes";
import Team from "./components/Team";
import NotFound from "./components/NotFound";
import Vote from "./components/Vote";
import Newsthread from "./components/Newsthread";

function App() {
    const [inputValue, setInputValue] = useState('')
  
    

  const [page, setPage] = useState("");

  const [darkMode, setDarkMode] = useState(false);

  // document.addEventListener('contextmenu', (e) => {
  //   e.preventDefault();
  // });

  // document.onkeydown = function(e) {
  //   if(e.keyCode === 123) {
  //   return false;
  //   }
  //   if(e.ctrlKey && e.keyCode === 'E'.charCodeAt(0)){
  //   return false;
  //   }
  //   if(e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)){
  //   return false;
  //   }
  //   if(e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)){
  //   return false;
  //   }
  //   if(e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)){
  //   return false;
  //   }
  //   if(e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)){
  //   return false;
  //   }
  //   if(e.ctrlKey && e.keyCode === 'S'.charCodeAt(0)){
  //   return false;
  //   }
  //   if(e.ctrlKey && e.keyCode === 'H'.charCodeAt(0)){
  //   return false;
  //   }
  //   if(e.ctrlKey && e.keyCode === 'A'.charCodeAt(0)){
  //   return false;
  //   }
  //   if(e.ctrlKey && e.keyCode === 'F'.charCodeAt(0)){
  //   return false;
  //   }
  //   if(e.ctrlKey && e.keyCode === 'E'.charCodeAt(0)){
  //   return false;
  //   }
  //   }

  return (
    // router
    <Router>
      {/* navbar */}
      <Navbar page={page} darkMode={darkMode} setDarkMode={setDarkMode}/>
      {/* routes */}
      <Routes>
        {/* route to home page */}
        <Route exact path="/" element={<Hero setPage={setPage} />} />
        {/* route to gamemodes page */}
        <Route
          exact
          path="/gamemodes"
          element={<Gamemodes setPage={setPage} />}
        />
        {/* route to the team page */}
        <Route
          exact
          path="/team"
          element={<Team setPage={setPage} />}
        />
        <Route
          exact
          path="/vote"
          element={<Vote setPage={setPage}/>}
        />
        {/* route to the applications page */}
        <Route
          path="/applications"
          element={<Applications setPage={setPage} />}
        />
        {/* route to the contact page */}
        <Route path="/contact" element={<Contact setPage={setPage} />} />
        <Route path="/newsthread/:id" element={<Newsthread setPage={setPage} />} />
        {/* redirect to not found page (404) */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
