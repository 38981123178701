import React from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'
import logo_text_1 from '../Images/__Final2Untitled-1.png'

export default function Navbar(props) {

  // document.getElementById(props.page).class


  return (
    <div>
      <header className="text-gray-600 body-font">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <Link
            className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
            to="/"
          >
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full"
              viewBox="0 0 24 24"
            >
              <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
            </svg> */}
            <img src="./assets/img/logo.png" alt="" width="50" />
            <img src={logo_text_1} alt="" width={250} className="ml-4"/>
          </Link>
          <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-sm font-semibold uppercase justify-center">
            <Link
              id="home"
              className={`page mr-5 hover:text-white px-3 py-2 rounded ${props.page === 'home' ? ' bg-opacity-100 bg-green-700 hover:bg-amber-500' : 'hover:bg-amber-500'} transition-colors duration-300`}
              to="/"
            >
              Home
            </Link>
            <Link
              id="gamemodes"
              className={`page mr-5 hover:text-white px-3 py-2 rounded ${props.page === 'gamemodes' ? 'bg-opacity-100 bg-green-700 hover:bg-amber-500' : 'hover:bg-amber-500'} transition-colors duration-300`}
              to="/gamemodes"
            >
              Gamemodes
            </Link>
            <Link
              id="team"
              className={`page mr-5 hover:text-white px-3 py-2 rounded ${props.page === 'team' ? 'bg-opacity-100 bg-green-700 hover:bg-amber-500' : 'hover:bg-amber-500'} transition-colors duration-300`}
              to="/team"
            >
              Team
            </Link>
            <Link
              id="apply"
              className={`page mr-5 hover:text-white px-3 py-2 rounded ${props.page === 'apply' ? 'bg-opacity-100 bg-green-700 hover:bg-amber-500' : 'hover:bg-amber-500'} transition-colors duration-300`}
              to="/applications"
            >
              Applications
            </Link>
            <Link
              id="contact"
              className={`page mr-5 hover:text-white px-3 py-2 rounded ${props.page === 'contact' ? 'bg-opacity-100 bg-green-700 hover:bg-amber-500' : 'hover:bg-amber-500'} transition-colors duration-300`}
              to="/contact"
            >
              Contact Us
            </Link>
            {/* <Link className="mr-5 hover:text-gray-800 px-2 py-1 rounded hover:bg-gray-100 transition-colors duration-100 cursor-not-allowed" to="/">
              Shop (Coming Soon)
            </Link> */}
          </nav>
          {/* <div className="mr-4 inline-flex">
          <span className="material-icons-outlined">dark_mode</span>
          <div
          className="md:w-14 md:h-7 w-12 h-6 scale-75 flex items-center bg-blue-100 rounded-full p-1 cursor-pointer"
          onClick={() => {
            props.setDarkMode(!props.darkMode);
          }}
        >
          Switch
          <div
            className={
              "bg-blue-500 md:w-6 md:h-6 h-5 w-5 rounded-full shadow-md transform duration-300 ease-in-out" +
              (props.darkMode ? null : toggleClass)
            }
          ></div>
        </div>
        <span className="material-icons-outlined">light_mode</span> */}
        {/* </div> */}
          
          <Link
          to="/vote"
            id="button"
            className="inline-flex mr-4 items-center bg-green-500 border-0 py-1 pr-2 focus:outline-none rounded hover:scale-105 shadow-xl shadow-green-400 hover:shadow-inner hover:shadow-green-700 transition duration-200 ease-in-out text-white mt-4 md:mt-0"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              className="mx-2 fill-white"
            >
              <path d="M 12.871094 3.882813 L 6.5 10.25 L 3.792969 7.542969 L 4.5 6.835938 L 6.5 8.835938 L 12.238281 3.101563 C 11.050781 1.835938 9.371094 1.042969 7.5 1.042969 C 3.910156 1.042969 1 3.953125 1 7.542969 C 1 11.132813 3.910156 14.042969 7.5 14.042969 C 11.089844 14.042969 14 11.132813 14 7.542969 C 14 6.183594 13.582031 4.925781 12.871094 3.882813 Z"></path>
            </svg>
            Vote
          </Link>
          
          <a target="_blank" rel="noreferrer" href="https://havenlands.online/discord">
          <button
            id="button"
            className="inline-flex items-center bg-indigo-500 border-0 py-1 pr-2 focus:outline-none rounded hover:scale-105 shadow-xl shadow-indigo-400 hover:shadow-inner hover:shadow-indigo-700 transition duration-200 ease-in-out text-white mt-4 md:mt-0"
          >
            <svg
              id="svg"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="20"
              height="20"
              viewBox="0 0 30 30"
              className="fill-white mx-2"
            >
              {" "}
              <path d="M25.12,6.946c-2.424-1.948-6.257-2.278-6.419-2.292c-0.256-0.022-0.499,0.123-0.604,0.357 c-0.004,0.008-0.218,0.629-0.425,1.228c2.817,0.493,4.731,1.587,4.833,1.647c0.478,0.278,0.638,0.891,0.359,1.368 C22.679,9.572,22.344,9.75,22,9.75c-0.171,0-0.343-0.043-0.501-0.135C21.471,9.598,18.663,8,15.002,8 C11.34,8,8.531,9.599,8.503,9.615C8.026,9.892,7.414,9.729,7.137,9.251C6.86,8.775,7.021,8.164,7.497,7.886 c0.102-0.06,2.023-1.158,4.848-1.65c-0.218-0.606-0.438-1.217-0.442-1.225c-0.105-0.235-0.348-0.383-0.604-0.357 c-0.162,0.013-3.995,0.343-6.451,2.318C3.564,8.158,1,15.092,1,21.087c0,0.106,0.027,0.209,0.08,0.301 c1.771,3.11,6.599,3.924,7.699,3.959c0.007,0.001,0.013,0.001,0.019,0.001c0.194,0,0.377-0.093,0.492-0.25l1.19-1.612 c-2.61-0.629-3.99-1.618-4.073-1.679c-0.444-0.327-0.54-0.953-0.213-1.398c0.326-0.443,0.95-0.541,1.394-0.216 C7.625,20.217,10.172,22,15,22c4.847,0,7.387-1.79,7.412-1.808c0.444-0.322,1.07-0.225,1.395,0.221 c0.324,0.444,0.23,1.066-0.212,1.392c-0.083,0.061-1.456,1.048-4.06,1.677l1.175,1.615c0.115,0.158,0.298,0.25,0.492,0.25 c0.007,0,0.013,0,0.019-0.001c1.101-0.035,5.929-0.849,7.699-3.959c0.053-0.092,0.08-0.195,0.08-0.301 C29,15.092,26.436,8.158,25.12,6.946z M11,19c-1.105,0-2-1.119-2-2.5S9.895,14,11,14s2,1.119,2,2.5S12.105,19,11,19z M19,19 c-1.105,0-2-1.119-2-2.5s0.895-2.5,2-2.5s2,1.119,2,2.5S20.105,19,19,19z"></path>
            </svg>
            Discord
          </button>
          </a>
        </div>
      </header>
    </div>
  );
}

Navbar.propTypes = {
  page: PropTypes.string,
  darkMode: PropTypes.bool,
  setDarkMode: PropTypes.func
}

Navbar.defaultProps = {
  page: 'home',
}