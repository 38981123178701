import React from "react";
import PropTypes from "prop-types";

export default function Alert(props) {
  return (
    <div class={`bg-${props.type === 'success' ? 'green' : props.type === 'failure' || props.type === 'error' ? 'red' : props.type === 'notice' ? 'blue' : props.type === "insufficient info" ? 'orange' : props.type === 'default' ? 'gray' : 'gray'}-900 text-center py-4 lg:px-4 ${props.hidden?'hidden':''}`}>
      <div
        class={`p-2 bg-${props.type === 'success' ? 'green' : props.type === 'failure' || props.type === 'error' ? 'red' : props.type === 'notice' ? 'blue' : props.type === "insufficient info" ? 'orange' : props.type === 'default' ? 'gray' : 'gray'}-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex`}
        role="alert"
      >
        <span class={`flex rounded-full bg-${props.type === 'success' ? 'green' : props.type === 'failure' || props.type === 'error' ? 'red' : props.type === 'notice' ? 'blue' : props.type === "insufficient info" ? 'orange' : props.type === 'default' ? 'gray' : 'gray'}-500 uppercase px-2 py-1 text-xs font-bold mr-3`}>
          {props.type}
        </span>
        <span class="font-semibold mr-2 text-left flex-auto">
			{props.text}
        </span>
        {/* <svg
          class="fill-current opacity-75 h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
        </svg> */}
      </div>
    </div>
  );
}

Alert.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  hidden: PropTypes.bool
};

Alert.defaultProps = {
	type: 'default',
	text: 'Default alert text',
	hidden: true
}
