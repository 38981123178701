import React, { useEffect, useState } from "react";
import { submitYTRankApplicationData } from "../../functions/sendApplicationData";

export default function YTRankApplication() {

  document.title = "HavenLands | YouTuber Rank Application";
  document.getElementById("metaTitle").content =
    "HavenLands | YouTuber Rank Application";
  document.getElementById("metaDesc").content =
    'Heya there! Are you a YouTuber? If so, you can get a cool "YouTuber" Role in our community with some amazing perks! Apply now!';

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(()=>{
    window.scrollTo(0,0)
  })

  return (
    <section className="text-gray-600 body-font relative">
      <div className="container px-5 py-24 mx-auto">
        <div className="w-full mx-auto mb-4 h-80 overflow-hidden rounded-lg">
          <img
            src="../assets/img/staffapplication.png"
            alt=""
            srcSet=""
            className="rounded-lg lg:-translate-y-72 lg:scale-y-90"
          />
        </div>
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
            YouTuber Rank Application
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Heya there! Are you a YouTuber? If so, you can get a cool "YouTuber"
            Role in our community with some amazing perks! Apply now!
          </p>
        </div>
        <div className="lg:w-1/2 mx-auto">
          <div className="flex flex-wrap -m-2">
            <div className="p-2 w-full">
              <div className="relative">
                <label htmlFor="name" className="leading-7 text-md text-gray-600">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  placeholder="Your name"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label htmlFor="age" className="leading-7 text-md text-gray-600">
                  Age <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  id="age"
                  name="age"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  placeholder="13+"
				  min={13}
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  htmlFor="discord"
                  className="leading-7 text-md text-gray-600"
                >
                  Discord ID (It should be like: username#0000){" "}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="discord"
                  name="discord"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required={true}
                  placeholder="Wumpus#6318"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  htmlFor="mcuname"
                  className="leading-7 text-md text-gray-600"
                >
                  Minecraft Username <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="mcuname"
                  name="mcuname"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required={true}
                  placeholder="MinecraftPlayer"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  htmlFor="lang"
                  className="leading-7 text-md text-gray-600"
                >
                  Language(s) (Separate by a ',' if more than one)
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="lang"
                  name="lang"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required={true}
                  placeholder="Separate each language by a `,`"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label htmlFor="why_should_we_consider_you" className="leading-7 text-md text-gray-600">
					Why should we consider you?
                  <span className="text-red-500">*</span>
                </label>
                <textarea
                  type="text"
                  id="why_should_we_consider_you"
                  name="why_should_we_consider_you"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required={true}
                  placeholder="Write in brief"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label htmlFor="name" className="leading-7 text-md text-gray-600">
                  Have you ever ben punished on HavenLands?{" "}
                  <span className="text-red-500">*</span>
                </label>

                <div className="flex flex-row">
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="inlineRadioOptions"
                    id="punished_opt_yes"
                    value="yes"
                  />
                  <label
                    className="mr-2 inline-block text-sm text-gray-800"
                    htmlFor="punished_opt_yes"
                  >
                    Yes
                  </label>
                  <input
                    className="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="inlineRadioOptions"
                    id="punished_opt_no"
                    value="no"
					defaultChecked
                  />
                  <label
                    className="inline-block text-sm text-gray-800"
                    htmlFor="punished_opt_no"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label htmlFor="channel" className="leading-7 text-md text-gray-600">
                  Link to your channel<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="channel"
                  name="channel"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required={true}
                  placeholder="https://www.youtube.com/"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label htmlFor="subscribers" className="leading-7 text-md text-gray-600">
                  How many subscribers do you have?
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  id="subscribers"
                  name="subscribers"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required={true}
                  placeholder="1200+"
                  min={1200}
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <button
                onClick={()=>{
					var name = document.getElementById("name").value;
					var age = document.getElementById("age").value;
					var discord = document.getElementById("discord").value;
					var mcuname = document.getElementById("mcuname").value;
					var lang = document.getElementById("lang").value;
					var why_should_we_consider_you = document.getElementById(
					  "why_should_we_consider_you"
					).value;
					var channel = document.getElementById("channel").value;
					var subscribers = document.getElementById("subscribers").value;
					var punished;
					if (document.getElementById("punished_opt_yes").checked) {
					  punished = true;
					} else if (document.getElementById("punished_opt_no").checked) {
					  punished = false;
					}
					name.replace(/\s+/g, '') !== "" && age >= 13 && discord.replace(/\s+/g, '') !== "" && mcuname.replace(/\s+/g, '') !== "" && lang.replace(/\s+/g, '') !== "" && why_should_we_consider_you.replace(/\s+/g, '') !== "" && channel.replace(/\s+/g, '') !== "" && subscribers >= 1200 ?
					submitYTRankApplicationData(name, age, discord, mcuname, lang, why_should_we_consider_you, punished, channel, subscribers, setLoading, setLoaded):
					alert("Please fill all the boxes or the requirements didn't meet!\n\nPlease contact our staff through our Discord Server for support.")
				}}
                id="submit"
                className={`flex disabled:bg-${
                  loaded ? "green" : "blue"
                }-100 disabled:border-transparent disabled:cursor-not-allowed mx-auto text-white bg-blue-500 border-2 border-transparent py-2 px-8 focus:outline-none focus:ring-4 focus:ring-blue-300 hover:bg-transparent hover:text-blue-500 hover:border-blue-500 transition-ease duration-200 rounded-full text-lg`}
                disabled={loading || loaded}
                data-modal-toggle="medium-modal"
              >
                <svg
                  id="spinner"
                  role="status"
                  className={`mr-2 w-8 h-8 text-gray-200 self-center animate-spin dark:text-gray-600 fill-blue-600 ${
                    loading ? "" : "hidden"
                  }`}
                  viewBox="0 0 100 101"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  ></path>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="32"
                  height="32"
                  viewBox="0 0 50 50"
                  className={`fill-green-500 ${loaded ? "" : "hidden"}`}
                >
                  {" "}
                  <path d="M43.171,10.925L24.085,33.446l-9.667-9.015l1.363-1.463l8.134,7.585L41.861,9.378C37.657,4.844,31.656,2,25,2 C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23C48,19.701,46.194,14.818,43.171,10.925z"></path>
                </svg>
                <span
                  className={`${loading || loaded ? "hidden" : ""}`}
                  id="sendButtonText"
                >
                  Submit
                </span>
              </button>
              <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                <a
                  href="mailto:havenlands20@gmail.com"
                  className="text-indigo-500"
                >
                  havenlands20@gmail.com
                </a>
                <p className="leading-normal my-5">
                  Join our discord server
                  <br />
                  for more information and support!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
