import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default function Application(props) {
  return (
    <div className="p-4 md:w-1/3">
      <div
        className={`flex rounded-lg h-full bg-${props.color}-100 p-8 flex-col`}
      >
        <div className="flex items-center mb-3">
          <div
            className={`w-12 h-12 mr-3 inline-flex items-center justify-center rounded-full bg-${props.color}-500 text-white flex-shrink-0`}
          >
            <span className="material-icons-outlined">{props.icon}</span>
          </div>
          <h2 className="text-gray-900 text-lg title-font font-medium">
            {props.title}
          </h2>
        </div>
        <div className="flex-grow">
          <p className="leading-relaxed text-base">{props.description}</p>
          <Link
            to={`/applications?form=${props.locked?'locked':props.link}`}
            className={`mt-3 text-${props.color}-500 inline-flex items-center ${props.locked?'cursor-not-allowed':'cursor-pointer'}`}
          >
            Apply {props.locked?'(Closed)':''}
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className={`w-4 h-4 ml-2 ${props.locked ? "hidden" : ""}`}
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              className={`w-4 h-4 ml-2 ${!props.locked ? "hidden" : ""}`}
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
}

Application.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  locked: PropTypes.bool
};

Application.defaultProps = {
  color: "gray",
  icon: "description",
  title: "Title",
  description: "Description",
  link: "",
  locked: false
};