import {firestore} from '../db'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore'

export async function submitYTRankApplicationData(name=String, age=Number, discord=String, mcuname=String, lang=String, why_should_we_consider_you=String, punished=Boolean, channel=String, subscribers=Number, setLoading, setLoaded) {
	setLoading(true)
	addDoc(collection(firestore, 'applications_yt'), {
		name: name,
		age: age,
		discord: discord,
		mcuname: mcuname,
		lang: lang,
		why_should_we_consider_you: why_should_we_consider_you,
		punished: punished,
		channel: channel,
		subscribers: subscribers,
		timestamp: serverTimestamp()
	}).then(doc=>{
		setLoading(false);
		setLoaded(true);
		alert("Your application for YouTuber Rank has been submitted successfully!\nApplication ID : ytr-"+doc.id)
	}).catch(err=>{
		setLoading(false);
		alert(err.msg+"\n\nPlease contact our staff in our Discord Server regarding the error.")
	})
}

export async function submitBuilderApplicationData(name=String, age=Number, discord=String, mcuname=String, aboutyourself=String, why_should_we_consider_you=String, frequencyMC=String, exp=Boolean, buildimg=String, setLoading, setLoaded){
	setLoading(true);
	addDoc(collection(firestore, 'applications_builder'), {
		name: name,
		age: age,
		discord: discord,
		mcuname: mcuname,
		aboutyourself: aboutyourself,
		why_should_we_consider_you: why_should_we_consider_you,
		frequencyMC: frequencyMC,
		exp: exp,
		buildimg: buildimg,
		timestamp: serverTimestamp()
	}).then(doc=>{
		setLoading(false);
		setLoaded(true);
		alert('Your application for being a Builder in our server has been submitted successfully!\nApplication ID : bldr-'+doc.id)
	}).catch(err=>{
		setLoading(false);
		alert(err.msg+"\n\nPlease contact our staff in our Discord Server regarding the error.")
	})
}

export async function submitStaffApplication(name=String, age=Number, discord=String, mcuname=String, role=String, aboutyourself=String, exp=Boolean, frequencyMC=String, server_crashes=String, someone_advertising = String, senior_abusing_power = String, someone_cheating = String, familiar_with=Array, setLoading, setLoaded) {
	setLoading(true);
	addDoc(collection(firestore, 'applications_staff'), {
		name: name,
		age: age,
		discord: discord,
		mcuname: mcuname,
		role: role,
		aboutyourself: aboutyourself,
		exp: exp,
		frequencyMC: frequencyMC,
		server_crashes: server_crashes,
		someone_advertising: someone_advertising,
		senior_abusing_power: senior_abusing_power,
		someone_cheating: someone_cheating,
		familiar_with: familiar_with,
		timestamp: serverTimestamp()
	}).then(doc=>{
		setLoading(false);
		setLoaded(true);
		alert('Your application for being a Staff in our server has been submitted successfully!\nApplication ID : stf-'+doc.id)
	}).catch(err=>{
		setLoading(false);
		alert(err.msg+"\n\nPlease contact our staff in our Discord Server regarding the error.")
	})
}