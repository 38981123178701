import React from 'react'
import PropTypes from 'prop-types'
import Gamemode from './Gamemode'

export default function Gamemodes(props) {

	props.setPage('gamemodes')

  return (
	<>
              {/* gamemodes page starts here | each card of gamemode <Gamemode /> is exported from /src/components/Gamemode.js */}
              <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h2 className="text-s text-green-400 tracking-widest font-semibold title-font mb-1">
              GAMEMODES
            </h2>
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-white font-bold">
              Explore to your heart's content!
            </h1>
          </div>
              <Gamemode
                name="Bedwars"
                about="Bedwars is an extremely fun game mode Where players spawn on an island and collect resources to obtain items such as swords, blocks, tools, and other special items."
                gamemode="bedwars"
                leftImg={true}
              />
              <Gamemode
                name="Skywars"
                about="Skywars is a multiplayer minigame where each player starts off on a separate island that is floating in the Void and tries to battle each other."
                gamemode="skywars"
                rightImg={true}
              />
              <Gamemode
                name="Skyblock"
                about="Skyblock is an opened world survival map where you start on a tiny island in the sky."
                gamemode="skyblock"
                leftImg={true}
              />
              <Gamemode
                name="Oneblock"
                about="One Block is a gamemode where players starts with one block floating in the sky. The player then has to dig up the same block for different materials."
                gamemode="oneblock"
                rightImg={true}
              />
              <Gamemode
                name="Survival"
                about="Survival is where the player must collect resources, build structures while surviving in the Server."
                gamemode="survival"
                leftImg={true}
              />
              <Gamemode
                name="The Bridge"
                about="The Bridge is a mode for the Duels minigame. It is a type of duel which involves 2 teams attempting to score goals on the opposite side by crossing a 1 block-wide bridge while attempting to protect their own goal."
                gamemode="thebridge"
                rightImg={true}
              />
              <Gamemode
                name="Build Battle"
                about="Build Battle, players face off against each other to see who can create the best build based on a theme"
                gamemode="buildbattle"
                leftImg={true}
              />
              <Gamemode
                name="Creative"
                about="Creative is a gamemode in which players get a Plot where they can build anything and show it to other players"
                gamemode="creative"
                rightImg={true}
              />
              </div>
            </>
  )
}


Gamemodes.propTypes = {
	setPage: PropTypes.func
}