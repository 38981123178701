import React from "react";
import PropTypes from 'prop-types'
// import Hypnosis from 'react-cssfx-loading/lib/Hypnosis';
import swal from 'sweetalert';
import showAlert from '../App'; 
import News from "./News";
  





export default function Hero(props) {

  document.title="HavenLands"
  document.getElementById("metaTitle").content = "HavenLands";
  document.getElementById("metaDesc").content = "HavenLands - An online minecraft community.";
  props.setPage('home')
  
  // const [loading, setLoading] = useState(true);

  // useEffect(()=>{
  //   const loadData = async () => {
  //     await new Promise((r)=>setTimeout(r, 2000));

  //     setLoading((loading)=>!loading);
  //   };

  //   loadData();
  // }, []);
  

  // if(loading){
  //   return <Hypnosis/>
  // }else{
    function copyip() {
      navigator.clipboard.writeText("play.havenlands.online")
      swal({
        title: "Done!",
        text: `Copied: play.havenlands.online`,
        icon: "success",
        
        
    
      })
      
    }

  return (
    <div>
              <script src="../js/newData.js" type="text/javascript" />

      <section className="text-gray-600 body-font">
        <div className="container mx-auto px-5 py-18 lg:grid lg:grid-cols-3 sm:grid-cols-1">
          <img
            className="2xl:w-80 xl:w-72 sm:w-60 mx-auto object-cover object-center  rounded"
            alt="logo"
            src="./assets/img/logo.png"
          />
          <div className="text-center w-6xl lg:mt-20 sm:mt-8">
            <h1 className="title-font 2xl:text-4xl xl:text-3xl xl:ml-36 2xl:mb-4 xl:mb-4 font-bold uppercase Bold bg-clip-text drop-shadow-xl text-transparent text-12321">
              HavenLands
            </h1>
            <p className="mb-8 leading-relaxed 2xl:text-xl xl:text-lg xl:relative  xl:left-16 xl:ml-2 text-pro">
              An online Minecraft Community with different fun gamemodes. It is secure and friendly with good support service.<br/> Join our discord server for more info!
            </p>
            
          </div>
        </div>
        <div className="flex items-center justify-center lg:ml-28 sm:ml-15">
              <button onClick={()=>{copyip()}} className="inline-flex hover:text-amber-500 text-white font-semibold hover:bg-amber-100 border-2 border-amber-500 py-2 px-6 focus:outline-none bg-amber-500 shadow-2xl hover:shadow-amber-300 shadow-amber-400 transition-colors duration-200 rounded-md text-lg">
                play.havenlands.online
              </button>
            </div>
        <News/>
      </section>
    </div>
    
  );

  // }
}

Hero.propTypes = {
  setPage: PropTypes.func
}
