import React, { useState, useEffect } from "react";
import { submitStaffApplication } from "../../functions/sendApplicationData";

export default function StaffApplication() {
  document.title = "HavenLands | Staff Application";
  document.getElementById("metaTitle").content =
    "HavenLands | Staff Application";
  document.getElementById("metaDesc").content =
    "This is the Application For Admin and Mod of Havenlands Server. If you are expecting payouts, we are sorry to disappoint you, but that is not possible.";
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(()=>{
    window.scrollTo(0,0)
  })

  return (
    <section className="text-gray-600 body-font relative">
      <div className="container px-5 py-24 mx-auto">
        <div className="w-full mx-auto mb-4 h-80 overflow-hidden rounded-lg">
          <img
            src="../assets/img/staffapplication.png"
            alt=""
            srcset=""
            className="rounded-lg lg:-translate-y-72 lg:scale-y-90"
          />
        </div>
        <div className="flex flex-col text-center w-full mb-12">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
            Staff Application
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            This is the Application For Admin and Mod of Havenlands Server. If
            you are expecting payouts, We are sorry to disappoint you, but that
            is not possible.
          </p>
        </div>
        <div className="lg:w-1/2 mx-auto">
          <div className="flex flex-wrap -m-2">
            <div className="p-2 w-full">
              <div className="relative">
                <label for="name" className="leading-7 text-md text-gray-600">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  placeholder="Your name"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label for="age" className="leading-7 text-md text-gray-600">
                  Age <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  id="age"
                  name="age"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  placeholder="13+"
                  min={13}
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  for="discord"
                  className="leading-7 text-md text-gray-600"
                >
                  Discord ID (It should be like: username#0000){" "}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="discord"
                  name="discord"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required={true}
                  placeholder="Wumpus#6318"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  for="mcuname"
                  className="leading-7 text-md text-gray-600"
                >
                  Minecraft Username <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="mcuname"
                  name="mcuname"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required={true}
                  placeholder="MinecraftPlayer"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label for="roles" className="leading-7 text-md text-gray-600">
                  What are you applying for?{" "}
                  <span className="text-red-500">*</span>
                </label>

                <select
                  name="roles"
                  id="roleSelector"
                  className="w-full bg-gray-100 bg-opacity-50 cursor-pointer rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-2 px-3 leading-8 transition-colors duration-200 ease-in-out"
                >
                  <option value="none" className="bg-gray-100 cursor-pointer">
                    Please select a role
                  </option>
                  <option value="admin" className="bg-gray-100 cursor-pointer">
                    Admin
                  </option>
                  <option value="dev" className="bg-gray-100 cursor-pointer">
                    Developer
                  </option>
                </select>
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  for="aboutyourself"
                  className="leading-7 text-md text-gray-600"
                >
                  Write about yourself <span className="text-red-500">*</span>
                </label>
                <textarea
                  type="text"
                  id="aboutyourself"
                  name="aboutyourself"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required={true}
                  placeholder="Your name"
                />
              </div>
            </div>

            <div className="p-2 w-full">
              <div className="relative">
                <label for="exp" className="leading-7 text-md text-gray-600">
                  Any experience as an admin on any other server?{" "}
                  <span className="text-red-500">*</span>
                </label>

                <div id="exp" className="flex flex-row">
                  <input
                    class="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="inlineRadioOptions"
                    id="exp_opt_yes"
                    value="yes"
                  />
                  <label
                    class="mr-2 inline-block text-sm text-gray-800"
                    for="exp_opt_yes"
                  >
                    Yes
                  </label>
                  <input
                    class="appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="radio"
                    name="inlineRadioOptions"
                    id="exp_opt_no"
                    value="no"
					defaultChecked
                  />
                  <label
                    class="inline-block text-sm text-gray-800"
                    for="exp_opt_no"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  for="frequencyMC"
                  className="leading-7 text-md text-gray-600"
                >
                  How often do you play minecraft?{" "}
                  <span className="text-red-500">*</span>
                </label>

                <input
                  type="range"
                  class="
		w-full
		h-6
		p-0
		bg-transparent
		cursor-pointer
		focus:outline-none focus:ring-0 focus:shadow-none
	  "
                  id="frequencyMC"
                />
                <div className=" flex justify-between text-sm">
                  <p>Very less</p>
                  <p>Frequently</p>
                  <p>A lot</p>
                </div>
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  for="server_crashes"
                  className="leading-7 text-md text-gray-600"
                >
                  What will you do if the server crashes?{" "}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="server_crashes"
                  name="server_crashes"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required={true}
                  placeholder="Write in brief"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  for="someone_advertising"
                  className="leading-7 text-md text-gray-600"
                >
                  What will you do if there is someone advertising on the
                  server? <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="someone_advertising"
                  name="someone_advertising"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required={true}
                  placeholder="Write in brief"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  for="senior_abusing_power"
                  className="leading-7 text-md text-gray-600"
                >
                  What will you do if you see the senior admin abusing their
                  powers? <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="senior_abusing_power"
                  name="senior_abusing_power"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required={true}
                  placeholder="Write in brief"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  for="someone_cheating"
                  className="leading-7 text-md text-gray-600"
                >
                  What will you do if you find someone cheating?{" "}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="someone_cheating"
                  name="someone_cheating"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required={true}
                  placeholder="Write in brief"
                />
              </div>
            </div>
            <div className="p-2 w-full">
              <div className="relative">
                <label
                  for="familiarSelector"
                  className="leading-7 text-md text-gray-600"
                >
                  Select all that you are familiar with ?{" "}
                  {/* <span className="text-red-500">*</span> */}
                </label>

                <div id="familiarSelector" className="flex flex-col">
                  <div className="flex flex-row my-1">
                    <input
                      class="appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      value="bc"
                      id="opt_bc"
                    />
                    <label
                      class="inline-flex text-gray-800 text-sm"
                      for="opt_bc"
                    >
                      Bungeecord
                    </label>
                  </div>
                  <div className="flex flex-row my-1">
                    <input
                      class="appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      value="we"
                      id="opt_we"
                    />
                    <label
                      class="inline-flex text-gray-800 text-sm"
                      for="opt_we"
                    >
                      World Edit
                    </label>
                  </div>
                  <div className="flex flex-row my-1">
                    <input
                      class="appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      value="wg"
                      id="opt_wg"
                    />
                    <label
                      class="inline-flex text-gray-800 text-sm"
                      for="opt_wg"
                    >
                      World Guard
                    </label>
                  </div>
                  <div className="flex flex-row my-1">
                    <input
                      class="appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      value="eX"
                      id="opt_eX"
                    />
                    <label
                      class="inline-flex text-gray-800 text-sm"
                      for="opt_eX"
                    >
                      EssentialsX
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-2 w-full">
              <button
                onClick={() => {
                  var name = document.getElementById("name").value;
                  var age = document.getElementById("age").value;
                  var discord = document.getElementById("discord").value;
                  var mcuname = document.getElementById("mcuname").value;
                  var aboutyourself =
                    document.getElementById("aboutyourself").value;
                  var server_crashes =
                    document.getElementById("server_crashes").value;
                  var someone_advertising = document.getElementById(
                    "someone_advertising"
                  ).value;
                  var senior_abusing_power = document.getElementById(
                    "senior_abusing_power"
                  ).value;
                  var someone_cheating =
                    document.getElementById("someone_cheating").value;
                  var exp;
                  document.getElementById("exp_opt_yes").checked
                    ? (exp = true)
                    : (exp = false);
                  var frequencyMC =
                    document.getElementById("frequencyMC").value;
                  var familiar_with = [];
                  if (document.getElementById("opt_bc").checked) {
                    familiar_with.push("Bungeecord");
                  }
                  if (document.getElementById("opt_we").checked) {
                    familiar_with.push("World Edit");
                  }
                  if (document.getElementById("opt_wg").checked) {
                    familiar_with.push("World Guard");
                  }
                  if (document.getElementById("opt_eX").checked) {
                    familiar_with.push("EssentialsX");
                  }
				  var role = document.getElementById('roleSelector').value;
				  name.replace(/\s+/g, '') !== "" && age >= 13 && discord.replace(/\s+/g, '') !== "" && mcuname.replace(/\s+/g, '') !== "" && role.replace(/\s+/g, '') !== "none" && aboutyourself.replace(/\s+/g, '') !== "" && frequencyMC.replace(/\s+/g, '') !== "" && server_crashes.replace(/\s+/g, '') !== "" && someone_advertising.replace(/\s+/g, '') !== "" && senior_abusing_power.replace(/\s+/g, '') !== "" && someone_cheating.replace(/\s+/g, '') !== "" ?
				  submitStaffApplication(name, age, discord, mcuname, role, aboutyourself, exp, frequencyMC, server_crashes, someone_advertising, senior_abusing_power, someone_cheating, familiar_with, setLoading, setLoaded):
                  alert("Please fill all the boxes or the requirements didn't meet!\n\nPlease contact our staff through our Discord Server for support.")
                }}
                id="submit"
                className={`flex disabled:bg-${
                  loaded ? "green" : "blue"
                }-100 disabled:border-transparent disabled:cursor-not-allowed mx-auto text-white bg-blue-500 border-2 border-transparent py-2 px-8 focus:outline-none focus:ring-4 focus:ring-blue-300 hover:bg-transparent hover:text-blue-500 hover:border-blue-500 transition-ease duration-200 rounded-full text-lg`}
                disabled={loading || loaded}
                data-modal-toggle="medium-modal"
              >
                <svg
                  id="spinner"
                  role="status"
                  className={`mr-2 w-8 h-8 text-gray-200 self-center animate-spin dark:text-gray-600 fill-blue-600 ${
                    loading ? "" : "hidden"
                  }`}
                  viewBox="0 0 100 101"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  ></path>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="32"
                  height="32"
                  viewBox="0 0 50 50"
                  className={`fill-green-500 ${loaded ? "" : "hidden"}`}
                >
                  {" "}
                  <path d="M43.171,10.925L24.085,33.446l-9.667-9.015l1.363-1.463l8.134,7.585L41.861,9.378C37.657,4.844,31.656,2,25,2 C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23C48,19.701,46.194,14.818,43.171,10.925z"></path>
                </svg>
                <span
                  className={`${loading || loaded ? "hidden" : ""}`}
                  id="sendButtonText"
                >
                  Submit
                </span>
              </button>
            </div>
            <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
              <a
                href="mailto:havenlands20@gmail.com"
                className="text-indigo-500"
              >
                havenlands20@gmail.com
              </a>
              <p className="leading-normal my-5">
                Join our discord server
                <br />
                for more information and support!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
