import React from "react";
import PropTypes from "prop-types";

export default function TeamMember(props) {
  document.title = "HavenLands | Team";
  document.getElementById('metaTitle').content = "HavenLands | Team";
  document.getElementById('metaDesc').content = "The helpful and friendly people who are helping us in the server to make it much more big."


  return (
    <div class="p-4 w-1/2">
      <div class="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
        <img
          alt="team"
          class={`flex-shrink-0 rounded-full w-32 h-32 object-cover object-center sm:mb-0 mb-4 shadow-2xl shadow-${props.shadowColor}-500`}
          src={props.pfp}
        />
        <div class="flex-grow sm:pl-8">
          <h2 class="title-font font-medium text-lg text-white">
            {props.name}
          </h2>
          <h3 class="text-gray-300 mb-3">{props.role}</h3>
          <p class="mb-4">{props.bio}</p>
          <span class="inline-flex">
            <a
              class={`mx-2 text-gray-500 ${props.fb === null ? "hidden" : ""}`}
              rel="noreferrer"
              target="_blank"
              href={`https://www.facebook.com/${props.fb}`}
            >
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
            <a
              class={`mx-2 text-gray-500 ${props.tw === null ? "hidden" : ""}`}
              rel="noreferrer"
              target="_blank"
              href={`https://www.twitter.com/${props.tw}`}
            >
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a>
            <a
              class={`mx-2 text-gray-500 ${props.ig === null ? "hidden" : ""}`}
              rel="noreferrer"
              target="_blank"
              href={`https://www.instagram.com/${props.ig}`}
            >
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                class="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
              </svg>
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}

TeamMember.propType = {
  name: PropTypes.string,
  role: PropTypes.string,
  bio: PropTypes.string,
  pfp: PropTypes.string,
  fb: PropTypes.string,
  ig: PropTypes.string,
  tw: PropTypes.string,
  shadowColor: PropTypes.string,
};

TeamMember.defaultProps = {
  name: "Name of User",
  role: "Role of User",
  bio: "Bio of User",
  pfp: "https://dummyimage.com/200x200",
  fb: null,
  ig: null,
  tw: null,
  shadowColor: "purple",
};
