import React from "react";
import Application from "./Application";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import StaffApplication from "./forms/StaffApplication";
import BuilderApplication from "./forms/BuilderApplication";
import YTRankApplication from "./forms/YTRankApplication";

export default function Applications(props) {
  document.title = "HavenLands | Applications";
  document.getElementById("metaTitle").content = "HavenLands | Applications";
	document.getElementById("metaDesc").content = "Browse through the different applications!";

  props.setPage("apply");

  const [searchParams] = useSearchParams();

  var form = searchParams.get("form");

  if (form === "staff_application") {
    return <StaffApplication/>;
  }else if (form === 'builder_application') {
    return <BuilderApplication/>
  }else if (form === 'ytrank_application') {
    return <YTRankApplication/>
  } else if (form === null || form === "") {
    return (
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h2 className="text-s text-green-400 tracking-widest font-semibold title-font mb-1">
              APPLICATIONS
            </h2>
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-white font-bold">
              Browse through the different applications!
            </h1>
          </div>
          <div className="flex flex-wrap -m-4">
            <Application
              color="green"
              icon="badge"
              title="Staff Application"
              description="This is the application for joining our community as a staff. Be honest and modest while answering the questions."
              link="staff_application"
              locked={false}
            />
            <Application
              color="amber"
              icon="construction"
              title="Builder Application"
              description="This is the application for all the minecraft builders are out there in the world if they are interested to help us build our server and make it a wonderful world."
              link="builder_application"
              locked={false}
            />
            <Application
              color="green"
              icon="gavel"
              title="Ban Appeal"
              description="Those who are banned from our Minecraft Server may file an appeal here. We will go through your appeal and if you are proved to be innocent, we would unban you."
              locked={true}
            />
            <Application
              color="amber"
              icon="code"
              title="Developer Application"
              description="Hey! Are you a developer? Well, if so, then you can help us! Fill up this form and we will go through your application shortly."
              locked={true}
            />
            <Application
              color="green"
              icon="play_circle_filled"
              title="YouTuber Rank Application"
              description='Heya there! Are you a YouTuber? If so, you can get a cool "YouTuber" Role in our community with some amazing perks! Apply now!'
              link="ytrank_application"
              locked={false}
            />
            <Application
              color="amber"
              icon="lock_reset"
              title="Password Reset"
              description="Did you forget your password to your forum or our Minecraft server? Don't worry! Just fill up the form and we will help you out!"
              locked={true}
            />
          </div>
        </div>
      </section>
    );
  }
}

Applications.propTypes = {
  setPage: PropTypes.func,
};