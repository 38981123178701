import { firestore } from "../db";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
// import { loadScript } from "./genFunc";
// import { executeReCaptcha } from "./reCaptcha";

export async function sendMsg(name, email, discord, mcuname, msg, setLoading, setLoaded, setRes) {
  setLoading(true);
  // const res = await loadScript('https://www.google.com/recaptcha/api.js?render=6LcmN84fAAAAAE0fZtjjjBG4_N1iU9R0_GeELHYL')
  
  // if(!res){
  //   alert('script not loaded');
  //   return
  // }

  // executeReCaptcha();
  await addDoc(collection(firestore, "contactus_messages"), {
    name: name,
    email: email,
    discord: discord,
    mcuname: mcuname,
    msg: msg,
    timestamp: serverTimestamp(),
  })
    .then((doc) => {
      setLoading(false);
	  setLoaded(true);
    setRes({
      type: 'success',
      msg: "Your message has been successfully sent to us! Reference code : " + doc.id,
      hidden: false
    });
    })
    .catch((err) => {
      setLoading(false);
      setRes({
        type: 'error',
        msg: err.msg,
        hidden: false
      });
    });
}
