import React, { useState } from "react";
import PropTypes from "prop-types";
import { sendMsg } from "../functions/sendMsgContact";
import Alert from "./Alert";

export default function Contact(props) {
  const [inputName, setName] = useState("");
  const [inputMsg, setMsg] = useState("");
  const [inputEmail, setEmail] = useState("");
  const [inputDiscord, setDiscord] = useState("");
  const [inputMcuName, setMcuName] = useState("");

  document.title = "HavenLands | Contact Us";
  document.getElementById("metaTitle").content = "HavenLands | Contact Us";
  document.getElementById("metaDesc").content =
    "Please submit whatever you want to say to us in the form here with the required information.";
  props.setPage("contact");

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [res, setRes] = useState({
    type: "default",
    msg: "Default alert message",
    hidden: true,
  });


  return (
    <section className="text-gray-600 body-font relative">
      <Alert type={res.type} text={res.msg} hidden={res.hidden} />
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-12">
          {/* <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">
            Contact Us
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-white">
            Please submit whatever you want to say to us in the below boxes with
            the required information.
          </p> */}
          {/* <div className="flex flex-col text-center w-full mb-20"> */}
            <h2 className="text-s text-green-400 tracking-widest font-semibold title-font mb-1">
              CONTACT US
            </h2>
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-white font-bold">
              Let's communicate! Fill up the boxes below and send us your thoughts!
            </h1>
          {/* </div> */}
        </div>
        <div className="lg:w-1/2 md:w-2/3 mx-auto">
          <div className="flex flex-wrap -m-2">
            <div className="p-2 w-1/2 mt-4 font-semibold uppercase">
              {/* <div className="relative">
                
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="peer w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required={true}
                  placeholder="Your name"
                  onChange={e=>{setName(e.target.value)}}
                />
                <label
                  htmlFor="name"
                  className="leading-7 text-sm text-white peer"
                >
                  Name
                </label>
              </div> */}
              <div className="relative">
									<input autoComplete="on" id="name" name="name" type="text" className="peer placeholder-transparent bg-transparent h-10 w-full border-b-2 border-green-300 text-white focus:outline-none focus:border-amber-500 transition ease-in-out duration-300" placeholder="Name" onChange={e=>{setName(e.target.value)}}/>
									<label htmlFor="name" className="absolute left-0 -top-3.5 text-white text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-white peer-focus:text-sm">Name</label>
							</div>
            </div>
            <div className="p-2 w-1/2 mt-4 font-semibold uppercase">
              {/* <div className="relative">
                <label
                  htmlFor="email"
                  className="leading-7 text-sm text-white"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required={true}
                  placeholder="example@email.com"
                  onChange={e=>{setEmail(e.target.value)}}
                />
              </div> */}
               <div className="relative">
									<input autoComplete="on" id="email" name="email" type="text" className="peer placeholder-transparent bg-transparent h-10 w-full border-b-2 border-green-300 text-white focus:outline-none focus:border-amber-500 transition ease-in-out duration-300" placeholder="Email" onChange={e=>{setEmail(e.target.value)}}/>
									<label htmlFor="email" className="absolute left-0 -top-3.5 text-white text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-white peer-focus:text-sm">Email</label>
							</div>
            </div>
            <div className="p-2 w-1/2 mt-4 font-semibold uppercase">
              <div className="relative">
                {/* <label
                  htmlFor="discord"
                  className="leading-7 text-sm text-white"
                >
                  Discord Username (optional)
                </label>
                <input
                  type="text"
                  id="discord"
                  name="discord"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required={false}
                  placeholder="Wumpus#6318"
                  onChange={e=>{setDiscord(e.target.value)}}
                /> */}
									<input autoComplete="on" id="discord" name="discord" type="text" className="peer placeholder-transparent bg-transparent h-10 w-full border-b-2 border-green-300 text-white focus:outline-none focus:border-amber-500 transition ease-in-out duration-300" placeholder="Discord Username" onChange={e=>{setDiscord(e.target.value)}}/>
									<label htmlFor="discord" className="absolute left-0 -top-3.5 text-white text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-white peer-focus:text-sm">Discord Username</label>
              </div>
            </div>
            <div className="p-2 w-1/2 mt-4 font-semibold uppercase">
                {/* <label
                  htmlFor="mcuname"
                  className="leading-7 text-white"
                >
                  Minecraft Username (optional)
                </label>
                <input
                  type="text"
                  id="mcuname"
                  name="mcuname"
                  className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  required={false}
                  placeholder="MinecraftPlayer"
                  onChange={e=>{setMcuName(e.target.value)}}
                /> */}
                <div className="relative">
									<input autoComplete="on" id="mcuname" name="mcuname" type="text" className="peer placeholder-transparent bg-transparent h-10 w-full border-b-2 border-green-300 text-white focus:outline-none focus:border-amber-500 transition ease-in-out duration-300" placeholder="Minecraft Username" onChange={e=>{setEmail(e.target.value)}}/>
									<label htmlFor="mcuname" className="absolute left-0 -top-3.5 text-white text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-white peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-white peer-focus:text-sm">Minecraft Username</label>
							</div>
            </div>
            <div className="p-2 w-full font-semibold uppercase">
              <div className="relative">
                <label
                  htmlFor="message"
                  className="leading-7 text-sm text-white"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  className="w-full bg-green-100 bg-opacity-50 rounded border border-green-300 focus:border-amber-500 focus:bg-white focus:ring-2 focus:ring-amber-200 h-32 outline-none text-gray-600 placeholder-white focus:placeholder-gray-600 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  required={true}
                  placeholder="Your message here"
                  onChange={e=>{setMsg(e.target.value)}}
                ></textarea>
              </div>
            </div>
            <div className="p-2 w-full">
              <button
                onClick={() => {
                  if (
                    inputName != "" && inputEmail != "" && inputMsg != ""
                  ) {
                    // this.recaptcha.execute()
                    sendMsg(
                      inputName,
                      inputEmail,
                      inputDiscord,
                      inputMcuName,
                      inputMsg,
                      setLoading,
                      setLoaded,
                      setRes
                    );
                    
                  } else {
                    setRes({
                      type: "insufficient info",
                      msg: "Please fill up the required boxes!",
                      hidden: false,
                    });
                  }
                }}
                id="send"
                className={`flex disabled:bg-${
                  loaded ? "green" : "amber"
                }-100 disabled:border-transparent disabled:cursor-not-allowed mx-auto text-white bg-amber-500 border-2 border-transparent py-2 px-8 font-semibold tracking-widest text-sm uppercase focus:outline-none focus:ring-4 focus:ring-blue-300 hover:bg-transparent hover:text-amber-500 hover:border-amber-500 transition-ease duration-200 rounded-full text-lg`}
                disabled={loading || loaded}
                data-modal-toggle="medium-modal"
              >
                <svg
                  id="spinner"
                  role="status"
                  className={`mr-2 w-8 h-8 text-gray-200 self-center animate-spin dark:text-gray-600 fill-blue-600 ${
                    loading ? "" : "hidden"
                  }`}
                  viewBox="0 0 100 101"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  ></path>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="32"
                  height="32"
                  viewBox="0 0 50 50"
                  className={`fill-green-500 ${loaded ? "" : "hidden"}`}
                >
                  {" "}
                  <path d="M43.171,10.925L24.085,33.446l-9.667-9.015l1.363-1.463l8.134,7.585L41.861,9.378C37.657,4.844,31.656,2,25,2 C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23C48,19.701,46.194,14.818,43.171,10.925z"></path>
                </svg>
                <span
                  className={`${loading || loaded ? "hidden" : ""}`}
                  id="sendButtonText"
                >
                  Send
                </span>
              </button>
              
            </div>
            <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
              <a
                href="mailto:havenlands20@gmail.com"
                className="text-amber-500"
              >
                havenlands20@gmail.com
              </a>
              <p className="leading-normal text-gray-200  my-5">
                Join our discord server
                <br />
                for more information and support!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Contact.propTypes = {
  setPage: PropTypes.func,
};
