import React from 'react'
import PropTypes from 'prop-types'
import TeamMember from './TeamMember'
import Om from "../Images/Om.gif"
import Sai from "../Images/Sai.gif"
import Krijan from "../Images/Krijan.gif"
import Roundabout from "../Images/Akash.gif"
import CHANG_NT1 from "../Images/Chang.gif"


export default function Team(props) {

	props.setPage('team')

  return (
	<section className="text-gray-600 body-font">
              {/* {document.title="HavenLands | Team"} */}
              <div className="container px-5 py-24 mx-auto">
                {/* <div className="flex flex-col text-center w-full mb-20">
                  <h1 className="text-2xl mb-4 text-white font-bold tracking-widest">
                    OUR TEAM
                  </h1>
                  <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-white">
                    The helpful and friendly people who are helping us in the
                    server to make it much more big.
                  </p>
                </div> */}
                <div className="flex flex-col text-center w-full mb-20">
            <h2 className="text-s text-green-400 tracking-widest font-semibold title-font mb-1">
              TEAM
            </h2>
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-white font-bold">
              Know the backbone of this operation!
            </h1>
          </div>
                <div className="flex flex-wrap -m-4">
                  <TeamMember
                    name="Om"
                    role="Owner and Creator"
                    bio=""
                    pfp={Om}
                    shadowColor="cyan"
                  />
                  <TeamMember
                    name="Sai"
                    role="Owner"
                    bio=""
                    pfp={Sai}
                    shadowColor="green"
                  />
                  <TeamMember
                    name="Krijan"
                    role="Head Manager and Developer"
                    bio=""
                    pfp={Krijan}
                    shadowColor="purple"
                  />
                  <TeamMember
                    name="Roundabout"
                    role="Manager and Web Developer"
                    bio=""
                    pfp={Roundabout}
                    shadowColor="red"
                  />
                  <TeamMember
                    name="CHANG_NT1"
                    role="Discord Admin"
                    bio=""
                    pfp={CHANG_NT1}
                    shadowColor="green"
                  />
                </div>
              </div>
            </section>
  )
}

Team.propTypes = {
	setPage: PropTypes.func
}
