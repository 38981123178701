import React from "react";
import PropTypes from "prop-types";

export default function Gamemode(props) {
  document.title = "HavenLands | Gamemodes";
  document.getElementById('metaTitle').content = "HavenLands | Gamemodes";
  document.getElementById('metaDesc').content = "Have a look at the gamemodes available in our minecraft server!"


  return (
    // <section className="text-gray-600 body-font">
    //   <div className="container px-5 py-24 mx-auto">
    //     <div className="flex items-center lg:w-4/5 mx-auto border-b pb-10 mb-2 border-gray-200 sm:flex-row flex-col">
    //       <div className={`${props.leftImg?'':'hidden'}`}>
    //         <img className={`${props.leftImg?'':'hidden'} sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center flex-shrink-0`} src="./assets/img/bedwars.png" alt="" />
    //       </div>
    //       <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
    //         <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
    //           {props.name}
    //         </h2>
    //         <p className="leading-relaxed text-base">{props.about}</p>
    //       </div>
    //       <div className={`${props.rightImg?'':'hidden'}`}>
    //         <img className={`${props.rightImg?'':'hidden'} sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center flex-shrink-0`} src="./assets/img/bedwars.png" alt="" />
    //       </div>
    //     </div>
    //   </div>
    // </section>
    // <section className="text-gray-600 body-font flex justify-center">
    //   <div className="container">
    //     <div className="flex border-b mb-2 border-gray-200 sm:flex-col flex-row w-full">
    //       <div className="w-1/4">
    //         <img className="gamemode-img" src="./assets/img/gamemode.png" alt="" />
    //       </div>
    //       div.flex.text-left.
    //     </div>
    //   </div>
    // </section>
    // <section class="text-gray-600 body-font">
    //   <div class="container px-5 py-24 mx-auto">
    //     <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
    //       <div class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
    //         <svg
    //           fill="none"
    //           stroke="currentColor"
    //           stroke-linecap="round"
    //           stroke-linejoin="round"
    //           stroke-width="2"
    //           class="sm:w-16 sm:h-16 w-10 h-10"
    //           viewBox="0 0 24 24"
    //         >
    //           <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
    //         </svg>
    //       </div>
    //       <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
    //         <h2 class="text-gray-900 text-lg title-font font-medium mb-2">
    //           Shooting Stars
    //         </h2>
    //         <p class="leading-relaxed text-base">
    //           Blue bottle crucifix vinyl post-ironic four dollar toast vegan
    //           taxidermy. Gastropub indxgo juice poutine.
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <div>
      <section className="text-gray-600 body-font">
        <div className="container mx-auto my-4 flex flex-wrap flex-col md:flex-row items-center rounded-lg shadow-xl scale-90">
          <div className="w-full flex justify-between">
            <div className="overflow-hidden">
              <img
                src={`./assets/img/${props.gamemode}.png`}
                alt={props.gamemode}
                className={`rounded-l-lg ${props.leftImg ? "" : "hidden"}`}
              />
            </div>
            <div className="px-2 py-4 mx-2 grid grid-cols-1 gap-2 content-center">
              <div className="text-md md:text-xl text-white">{props.name}</div>
              <div className="text-sm md:text-lg text-gray-100">{props.about}</div>
            </div>
            <div>
              <img
                src={`./assets/img/${props.gamemode}.png`}
                alt="gamemode"
                className={`rounded-r-lg ${props.rightImg ? "" : "hidden"}`}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

Gamemode.propTypes = {
  name: PropTypes.string,
  about: PropTypes.string,
  gamemode: PropTypes.string,
  leftImg: PropTypes.bool,
  rightImg: PropTypes.bool,
};

Gamemode.defaultProps = {
  name: "Name",
  about: "About",
  gamemode: "gamemode",
  leftImg: false,
  rightImg: false,
};
